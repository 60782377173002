.chatMessageToolCallItem {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: var(--inner-spacing-4);
  padding: var(--inner-spacing-4) var(--inner-spacing-5);
  border-radius: var(--border-radius-elevation-3);
  border: var(--border-width-1) solid var(--bd-on-neutral-subtle, #e5e6e7);
}

.icon {
  width: 16px;
  height: 16px;

  img {
    width: 100%;
    height: 100%;
  }
}

.status {
  margin: 0 0 0 auto;
  padding: 2px 4px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  border-radius: var(--border-radius-elevation-3);
}

.statusInProgress {
  background: var(--color-bg-neutral-subtle);
}

.statusPendingApproval {
  color: var(--color-fg-warning);
  background: var(--color-bg-warning-subtle);
}

.statusSuccess {
  color: var(--color-fg-positive);
  background: var(--color-bg-positive-subtle);
}

.statusError {
  color: var(--color-fg-negative);
  background: var(--color-bg-negative-subtle);
}

.arguments {
  padding: var(--inner-spacing-2);
  border-radius: var(--border-radius-elevation-3);
  border: var(--border-width-1) solid var(--bd-on-neutral-subtle, #e5e6e7);
  background: var(--color-bg-neutral-subtle);
}

.argumentsJson {
  font-size: 12px;
  padding: var(--inner-spacing-2);
}

.statusDetails {
  display: flex;
  gap: var(--inner-spacing-2);
  align-items: center;
  justify-content: space-between;
  padding: var(--inner-spacing-3);
}

.statusDetailsSuccess {
  background: var(--color-bg-positive-subtle);
}

.statusDetailsError {
  background: var(--color-bg-negative-subtle);
}

.statusDetailsPendingApproval {
  background: var(--color-bg-warning-subtle);
}

.statusDescriptionSuccess {
  color: var(--color-fg-positive);
}

.statusDescriptionError {
  color: var(--color-fg-negative);
}

.statusDescriptionPendingApproval {
  color: var(--color-fg-warning);
}
