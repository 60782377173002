.pdfDocumentViewer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  contain: content;
}

.zoomButtons {
  display: flex;
  gap: var(--inner-spacing-2);
  padding: var(--inner-spacing-3);
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 0 auto;
}

.content {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.document {
  height: 100%;
}

.document[data-hidden="true"] {
  opacity: 0;
}

.citationHighlight {
  position: absolute;
  background: rgba(250, 244, 100, 0.3);
}
