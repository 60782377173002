.textDocumentViewer {
  position: relative;
  height: 100%;
  width: 100%;
}

.content {
  position: absolute;
  inset: 0;
  overflow: auto;
  padding: var(--inner-spacing-4);
}

.content[data-loading] {
  opacity: 0;
  overflow: hidden;
}

.content[data-processing] {
  opacity: 0.5;
}
